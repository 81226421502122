@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Amatica+SC:wght@400;700&display=swap');



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes float {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    50% {
        transform: translateY(-20px);
        opacity: 0.8;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes float-reverse {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    50% {
        transform: translateY(20px);
        opacity: 0.8;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.announcement-content ol {
    list-style: decimal;
    padding-left: 20px;
}

.announcement-content ul {
    list-style: disc;
    padding-left: 20px;
}



.animate-float {
    animation: float 2s ease-in-out infinite;
}

.animate-float-reverse {
    animation: float-reverse 2s ease-in-out infinite;
}

.announcement-content::-webkit-scrollbar {
    width: 6px;
}

.announcement-content::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
}

    .announcement-content::-webkit-scrollbar-thumb:hover {
        background-color: rgba(255, 255, 255, 0.7);
    }
